[class^="icon-"], [class*=" icon-"] {
    background:url(./icon.png) no-repeat;display:inline-block;vertical-align:middle;}
.icon-content, .icon-assignment, .icon-report, .icon-logout{width:34px;height:34px;margin-right: 5px;}
.icon-content{background-position: 0 0;}
.icon-assignment{background-position: 0 -139px;}
.icon-report{background-position: 0 -236px;}
.icon-logout{background-position: 0 -301px;}
a:hover .icon-content, a:focus .icon-content, .active .icon-content{background-position:0 -38px;}
a:hover .icon-assignment, a:focus .icon-assignment, .active .icon-assignment{background-position:0 -91px;}
a:hover .icon-report, a:focus .icon-report, .active .icon-report{background-position:0 -193px;}
a:hover .icon-logout, a:focus .icon-logout, .active .icon-logout{background-position:0 -350px;}

.icon-radio{width:20px;height:20px;background-position: -470px -57px;margin-top: -2px;margin-right: 7px;}
.custom-radio-btn:hover .icon-radio, .custom-radio-btn:focus .icon-radio{background-position: -470px 0;}
.custom-radio-btn.active .icon-radio{background-position: -470px -139px;}

.icon-edit{width:16px;height: 16px;background-position: -220px 0;}
.btn:hover .icon-edit, .btn:focus .icon-edit{background-position: -220px -59px;}
.btn.active .icon-edit{background-position: -220px -132px;}

.icon-layout-h{width: 48px;height:21px;background-position: -99px -4px;}
.btn-layout:hover .icon-layout-h, .btn-layout:focus .icon-layout-h{background-position: -99px -61px;}
.btn-layout.active .icon-layout-h{background-position: -99px -125px;}
.icon-layout-v{width:21px;height:41px;background-position: -179px 0;}
.btn-layout:hover .icon-layout-v, .btn-layout:focus .icon-layout-v{background-position: -179px -56px;}
.btn-layout.active .icon-layout-v{background-position: -179px -116px;}

.icon-multiplication-table, .icon-addition, .icon-subtraction, .icon-multiplication, .icon-division, .icon-mixed1, .icon-mixed2{
    width:40px;height:40px;position: absolute;top:-8px;margin-left:10px;}
.icon-multiplication-table{background-position:-102px -181px;}
.icon-addition{background-position:-158px -181px;}
.icon-subtraction{background-position:-213px -181px;}
.icon-multiplication{background-position:-270px -181px;}
.icon-division{background-position:-326px -181px;}
.icon-mixed1{background-position:-375px -181px;width:79px;}
.icon-mixed2{background-position:-462px -181px;width:79px;}

.icon-addition-sm, .icon-subtraction-sm, .icon-multiplication-sm, .icon-division-sm, .icon-mixed1-sm, .icon-mixed2-sm{
    width:27px;height:27px;position: relative;top:-4px;margin-right: 10px;vertical-align:middle;}
.icon-addition-sm{background-position:-46px -248px;}
.icon-subtraction-sm{background-position:-145px -248px;}
.icon-multiplication-sm{background-position:-223px -248px;}
.icon-division-sm{background-position:-293px -248px;}
.icon-mixed1-sm{background-position:-388px -248px;width:57px;}
.icon-mixed2-sm{background-position:-485px -248px;width:57px;}
.quiz-item:hover .icon-addition-sm{background-position:-46px -294px;}
.quiz-item:hover .icon-subtraction-sm{background-position:-145px -294px;}
.quiz-item:hover .icon-multiplication-sm{background-position:-223px -294px;}
.quiz-item:hover .icon-division-sm{background-position:-293px -294px;}
.quiz-item:hover .icon-mixed1-sm{background-position:-388px -294px;}
.quiz-item:hover .icon-mixed2-sm{background-position:-485px -295px;}

.icon-play, .icon-edit2, .icon-edit3, .icon-assign{width:22px;height:22px;display:inline-block;vertical-align:middle;}
.icon-play{background-position:-314px -120px;}
.icon-edit2{background-position:-363px -120px;}
.icon-edit3{background-position:-395px -120px;}
.icon-assign{background-position:-424px -120px;}
.quiz-item:hover .icon-play{background-position:-314px -57px;}
.quiz-item:hover .icon-edit2{background-position:-363px -57px;}
.quiz-item:hover .icon-edit3{background-position:-395px -57px;}
.quiz-item:hover .icon-assign{background-position:-424px -57px;}
.btn:hover .icon-play, .btn:focus .icon-play{background-position:-314px -3px;}
.btn:hover .icon-edit2, .btn:focus .icon-edit2{background-position:-363px -2px;}
.btn:hover .icon-edit3, .btn:focus .icon-edit3{background-position:-395px -2px;}
.btn:hover .icon-assign, .btn:focus .icon-assign{background-position:-423px -1px;}

.icon-close{height:38px;width:38px;background-position: -257px -49px;}
.close:hover .icon-close{background-position: -257px 0;}

.icon-back{width:43px;height:43px;background-position: -44px 0;}
a:hover .icon-back, a:focus .icon-back{background-position: -44px -52px;}
.icon-back2{width:46px;height:46px;background-position: -247px -336px;}
a:hover .icon-back2, a:focus .icon-back2{background-position: -247px -395px;}

.icon-trash{width:19px;height:25px;background-position: 0 -395px;}

.icon-randomize{width:23px;height:20px;background-position: -55px -133px;margin-right: 5px;}
.btn:hover .icon-randomize, .btn:focus .icon-randomize{background-position: -55px -111px;}
.icon-present{background-position: -52px -203px;width:27px;height:27px;margin-right: 5px;}

.icon-eye{width:20px;height: 13px;background-position: -56px -181px;margin-left: 5px;}
.btn:hover .icon-eye, .btn:focus .icon-eye{background-position: -56px -159px;}

.icon-volume{width:46px;height:38px;background-position: -46px -331px;}
.btn:hover .icon-volume{background-position: -46px -385px;}
.btn.active .icon-volume{background-position: -174px -331px;}
.btn.active:hover .icon-volume{background-position: -174px -385px;}

.icon-close2{width:30px;height:30px;background-position: -323px -335px;}
.btn:hover .icon-close2{background-position: -323px -389px;}

.icon-skip, .icon-prev{width:21px;height:37px;background-position:-409px -334px;position: relative;top:-8px;margin-right:15px;}
.icon-prev{background-position:-409px -383px;}

.icon-times-red{width:27px;height: 27px;background-position: -270px -115px;}
.icon-check-green{width:27px;height:27px;background-position: -120px -336px;}
.icon-calendar{width: 49px;height:49px;background-position: -464px -336px;}
